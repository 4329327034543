import * as React from 'react';

import { HeroNav } from '../hero/hero-nav';
import { CTA } from './cta';
import { HeroImage } from './hero-image';

function Hero() {
  return (
    <header className="relative">
      <div className="absolute inset-0">
        <div className="absolute inset-0 flex">
          <HeroImage />
        </div>
      </div>

      <div className="relative flex justify-center hero-gradient">
        <div className="max-w-6xl px-6 py-4 ">
          <HeroNav />
          <div className="grid lg:grid-cols-2">
            <div className="flex flex-col justify-between col-span-1 pb-8">
              <CTA />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export { Hero };
