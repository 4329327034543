import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { useKeenSlider } from 'keen-slider/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ArrowIcon from '../icons/arrow';

function Slider() {
  const { allPeopleWeWorkWithJson } = useStaticQuery(graphql`
    {
      allPeopleWeWorkWithJson {
        nodes {
          id
          address
          email
          image {
            childImageSharp {
              fluid(maxWidth: 512) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          logo {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          name
          phone
          quote
          title
          website
        }
      }
    }
  `);

  const people = allPeopleWeWorkWithJson.nodes;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [opacities, setOpacities] = useState([]);
  const [sliderRef, slider] = useKeenSlider({
    spacing: 16,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    duration: 3000,
    move(s) {
      const newOpacities = s.details().positions.map((slide) => slide.portion);
      // @ts-ignore
      setOpacities(newOpacities);
    },
  });

  return (
    <article className="w-full max-w-6xl mx-auto mt-12">
      <Dots
        people={people}
        currentSlide={currentSlide}
        // @ts-ignore
        setCurrentSlide={setCurrentSlide}
        slider={slider}
      />
      <div className="relative w-full max-w-5xl px-6 mx-auto mt-8 sm:px-8 md:px-12">
        <ul
          // @ts-ignore
          ref={sliderRef}
          className="keen-slider md:border-4 border-brand-orange"
        >
          {people.map((person, index) => (
            <Slide
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              person={person}
              opacities={opacities}
              index={index}
              currentSlide={currentSlide}
            />
          ))}
        </ul>
        {slider && (
          <>
            <ArrowLeft
              onClick={(e) => e.stopPropagation() || slider.prev()}
              isDisabled={currentSlide === 0}
            />

            <ArrowRight
              onClick={(e) => e.stopPropagation() || slider.next()}
              isDisabled={currentSlide === slider.details().size - 1}
            />
          </>
        )}
      </div>
    </article>
  );
}

function Dots({ people, currentSlide, slider }) {
  return (
    <div className="flex-wrap justify-center hidden md:flex">
      {people.map((person, index) => (
        <button
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          type="button"
          className={`w-24 h-24 m-2 overflow-hidden p-1 transition duration-300 ease-in-out border-4 rounded-full focus:outline-none focus:ring ${
            currentSlide === index
              ? 'border-brand-orange'
              : 'border-transparent'
          }`}
          onClick={() => slider.moveToSlideRelative(index)}
        >
          <GatsbyImage
            fluid={person.image.childImageSharp.fluid}
            className="h-full rounded-full"
          />
        </button>
      ))}
    </div>
  );
}
Dots.propTypes = {
  people: PropTypes.array,
  currentSlide: PropTypes.number,
  slider: PropTypes.object,
};

function Slide({ person, opacities, index, currentSlide }) {
  const [width, setWidth] = useState(null);
  const breakpoint = 768;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      setWidth(window.innerWidth);
      // @ts-ignore
      window.addEventListener('resize', () => setWidth(window.innerWidth));
    }
  }, [width]);

  const [isOpen, setOpen] = useState(true);

  useEffect(() => {
    // @ts-ignore
    if (width < breakpoint) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [currentSlide, width]);

  return (
    <li
      className="keen-slider__slide"
      key={index}
      style={{ opacity: opacities[index] }}
    >
      <div className="relative grid pt-24 mx-auto md:grid-cols-3 md:pt-0">
        <div className="absolute top-0 w-48 h-48 col-span-1 overflow-hidden transform -translate-x-1/2 rounded-full left-1/2 md:relative md:w-full md:h-full md:rounded-none">
          <GatsbyImage
            fluid={person.image.childImageSharp.fluid}
            className="w-full h-full"
          />
        </div>
        <div className="col-span-2 pt-24 pb-4 border-4 md:p-4 md:border-0 border-brand-orange md:pt-4">
          <h2 className="p-4 mt-4 text-5xl font-semibold leading-none text-center uppercase md:text-4xl font-display text-brand-blue md:mt-0 md:text-left md:p-0">
            Supporting the locals with{' '}
            <span className="inline-block text-brand-orange">
              {person.name}
            </span>
          </h2>

          <button
            type="button"
            className="block mx-auto md:hidden focus:outline-none "
            onClick={() => setOpen((prevOpen) => !prevOpen)}
          >
            <ArrowIcon
              className={`w-6 h-auto transition duration-300 transform ${
                isOpen ? '-rotate-90' : 'rotate-90'
              } text-brand-orange`}
            />
          </button>

          <AnimatePresence>
            {isOpen && (
              <motion.div
                className="relative p-4 mt-4 border-t-4 md:block md:p-0 md:mt-0 md:border-t-0 border-brand-orange"
                // @ts-ignore
                initial={width > breakpoint ? 'open' : 'collapsed'}
                animate="open"
                // @ts-ignore
                exit={width > breakpoint ? 'open' : 'collapsed'}
                variants={{
                  open: { height: 'auto', opacity: 1, overflow: 'visible' },
                  collapsed: { height: 0, opacity: 0, overflow: 'hidden' },
                }}
                transition={{ min: 0, max: 100, bounceDamping: 9 }}
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: person.quote }}
                  className="mt-4 prose"
                />
                <div className="md:flex md:items-end">
                  <dl className="flex-1 mt-6 space-y-1 text-sm md:mt-2">
                    <div>
                      <dt className="inline font-semibold">{person.name}: </dt>
                      <dd className="inline-block">{person.title}</dd>
                    </div>
                    <div>
                      <dt className="inline font-semibold">Phone: </dt>
                      <dd className="inline-block">
                        <a href={`tel:${person.phone.split(' ').join('')}`}>
                          {person.phone}
                        </a>
                      </dd>
                    </div>
                    <div>
                      <dt className="inline font-semibold">Address: </dt>
                      <dd className="inline-block">{person.address}</dd>
                    </div>
                    <div>
                      <dt className="inline font-semibold">Email: </dt>
                      <dd className="inline-block">
                        <a href={`mailto:${person.email}`}>{person.email}</a>
                      </dd>
                    </div>
                    <div>
                      <dt className="inline font-semibold">Website: </dt>
                      <dd className="inline-block">
                        <a
                          href={person.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {person.website}
                        </a>
                      </dd>
                    </div>
                  </dl>
                  <div className="mt-4">
                    <GatsbyImage
                      fluid={person.logo.childImageSharp.fluid}
                      style={{
                        width: 262,
                        height: 40,
                      }}
                      className="w-full h-full"
                    />
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </li>
  );
}

Slide.propTypes = {
  person: PropTypes.object,
  opacities: PropTypes.array,
  index: PropTypes.number,
  currentSlide: PropTypes.number,
};
function ArrowLeft({ isDisabled, onClick }) {
  return (
    <div className="absolute inset-y-0 md:flex md:items-center left-4 md:left-4">
      <button
        type="button"
        onClick={onClick}
        disabled={isDisabled}
        className="focus:outline-none focus:ring"
      >
        <ArrowIcon
          className={`${
            isDisabled ? 'opacity-50' : 'opacity-100'
          } text-brand-orange transform rotate-180 w-6 h-auto`}
        />
      </button>
    </div>
  );
}

ArrowLeft.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

function ArrowRight({ isDisabled, onClick }) {
  return (
    <div className="absolute inset-y-0 md:flex md:items-center right-6 md:right-4">
      <button
        type="button"
        onClick={onClick}
        disabled={isDisabled}
        className="focus:outline-none focus:ring"
      >
        <ArrowIcon
          className={`${
            isDisabled ? 'opacity-50' : 'opacity-100'
          } text-brand-orange w-6 h-auto`}
        />
      </button>
    </div>
  );
}

ArrowRight.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export { Slider };
