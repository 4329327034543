/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function ArrowIcon(props) {
  return (
    <svg viewBox="0 0 65 163" {...props}>
      <path
        fill="#f05f33"
        fillRule="evenodd"
        d="M.981 162.974L51.519 81.5.981.026h12.9L64.425 81.5l-50.54 81.471H.981z"
        data-name="Arrow 2 1"
      />
    </svg>
  );
}
export default ArrowIcon;
