import * as React from 'react';

import { Layout, SEO } from '../components';
import { Hero, Slider } from '../components/people-we-work-with';

const PeopleWeWorkWithPage = () => (
  <Layout formName="bottom-form" footerIsGray>
    <SEO title="Businesses We Work With | Frontline Removals" />
    <Hero />
    <Slider />
  </Layout>
);

export default PeopleWeWorkWithPage;
