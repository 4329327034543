import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import * as React from 'react';

function HeroImage() {
  const { file } = useStaticQuery(graphql`
    {
      file(name: { eq: "businesses-we-work-with-hero" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100, traceSVG: { color: "#152735" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Image
      fluid={file.childImageSharp.fluid}
      loading="eager"
      className="flex-1"
    />
  );
}

export { HeroImage };
